.home * {
  margin: 0;
  padding: 0;
}

.divider-line{
  width: 80%;
  height: 1px;
  border: none;
  background-color: #CCCCCC;
  margin-left: 0%;
}
/*-------------------------------------------------------------*\
   #SECTION HOME-NAVBAR
  \*-------------------------------------------------------------*/
.main-home-navbar {
  width: 100%;
  margin: 0 auto;
}
.container-logo-nav img {
  height: 70px;
}
/* Mobile first */
.container-logo-nav {
  display: none;
}
.container-logo-mobile-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 22px;
  background-color: white;
}
.navigation-mobile {
  background-color: white;
  transition: all 0.3s ease;
  height: 690px;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 1;
}
.logo-mobile {
  padding: 5% 5% 0 0;
  display: flex;
}
.cancel-nav {
  position: absolute;
  margin-top: -14%;
  margin-left: 90%;
}
.navigation-mobile-hide {
  max-height: 0px;
  overflow: hidden;
}

#btn-menu {
  display: none;
}
.icon-menu {
  cursor: pointer;
}
.navigation-mobile-links a {
  display: flex;
  justify-content: flex-start;
  color: #323232;
  padding: 5% 6%;
  font-family: AvenirRoman;
  font-size: 16px;
}
.borde-nav {
  border: 1px solid #c4c4c4;
  margin-left: 5%;
  width: 90%;
  margin-bottom: 2%;
}
.register {
  height: 50px;
  line-height: 25px;
  font-family: AvenirBlack;
  margin: 30px 0;
}
a.link-register{
  font-family: AvenirBlack;
  font-size: 16px;
  padding: 0% 12% 0% 13%;
}

.dropdown-button {
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
  font-family: "ABeeZee", sans-serif;
  font-size: 14px;
  color: #323232;
}
.dropdown-button img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border-radius: 100%;
}
.menuItem {
  font-family: "ABeeZee", sans-serif;
  font-size: 14px;
  color: #323232;
  padding: 5px 0;
}
.sider__menu__element {
  margin-right: 10px;
  font-size: 16px;
}
/* Desktop */
@media (min-width: 768px) {
  .container-logo-mobile-nav {
    display: none;
  }
  .navigation-mobile {
    display: none;
  }
  .container-logo-nav {
    display: flex;
    width: 100%;
    padding: 0;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    font-family: "ABeeZee", sans-serif;
    color: #323232;
  }
  .logo{
    margin-left: 3%;
  }
  .navigation_end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 35px;
    font-size: 16px;
    color: #323232;
  }
  .navigation_end a{
    cursor: pointer;
  }
  .navigation_end a:hover{
    color: #95BF48;
  }
  .nav_premios{
    margin-right: 20px;
  }
  .navigation__end-separatorline {
    border-left: 2px solid rgba(155, 99, 99, 0.16);
    margin: 0px 15px;
    width: 1px;
    height: 25px;
  }
}

@media (min-width: 1024px) {
  .container-logo-mobile-nav {
    display: none;
  }
  .navigation-mobile {
    display: none;
  }
  .container-logo-nav {
    display: flex;
    background-color: white;
    width: 100%;
    padding: 0 5%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    font-family: "ABeeZee", sans-serif;
    color: #323232;
  }
  .navigation_end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 12px;
  }
  .navigation__end-separatorline {
    border-left: 2px solid rgba(155, 99, 99, 0.16);
    margin: 0px 15px;
    width: 1px;
    height: 25px;
  }
}
/*-------------------------------------------------------------*\
   #SECTION HOME-CONTAINER
  \*-------------------------------------------------------------*/
  .home-welcome{
    width: 100%;
    height:auto;
   
    background-image: url("https://firebasestorage.googleapis.com/v0/b/woven-catwalk-285723.appspot.com/o/welcome.png?alt=media&token=f111d23e-2789-4517-8e1e-3b2034b3a5b5");
    background-position: right;
    background-size: cover;

  }
  .velo{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 40px;
    
  }
  .home-welcome-form{
    width: 600px;
   flex-grow: 1;
    margin-top: 48px;
  }
#title2{
    color:#595959;
    font-size: 24px;
    margin-bottom: 24px;
  }
  .home-welcome-card{
    padding: 5% 5% 2% 5%;
    box-sizing: border-box;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    background-color: white;
    height: auto;
    border-radius: 16px;
    box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  }
  .home-welcome-text{
    padding: 0 8%;
    width: 600px;
    flex-grow: 1;
  }
  .home-container__text1 {
    color: white;
    font-family: Paytone One;
    font-size: 2rem;
    font-weight: normal;

  }
  .home-container__text2 {
    font-size: 2rem;
    color: rgb(149, 191, 72);
    font-family: Paytone One;
  }
  .home-container__text2 strong {
    color: rgb(149, 191, 72) !important;
    font-size: 2rem;
  } 
  .home-container__p {
    font-size: 1.5rem;
    font-family: Helvetica Neue;
    font-weight: normal;
    line-height: 28px;
    color: #ffffff;
    text-align: center;
  }
  .home-container_desktop__p {
    display: none;
  }
  .btn-sg{
    background: rgb(149, 191, 72);
    color: rgb(255, 255, 255);
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 40px;
    font-weight: 600;
    line-height: initial;
  }
  .terms{
    font-size: 14px !important;
  }
  .input-mid{
    display: flex;
    justify-content: space-between;
   flex-wrap: wrap;

  }
  .input-mid div{
    width: 100%;
  }
  .input-mid div div{
    width: 100%;
  }
  .ant-form-vertical .ant-form-item{
    flex-direction: initial;
  }
  .ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control{
    flex: none !important;
  }
/* 
  .home-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #95bf48;
    padding-top: 8%;
  }
  .home-container__left {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .home-container__text1 {
    color: white;
    font-family: Paytone One;
    font-size: 2rem;
    font-weight: normal;

  }
  .home-container__text2 {
    font-size: 2rem;
    color: #7ea13d !important;
    font-family: Paytone One;
  }
  .home-container__text2 strong {
    color: #7ea13d;
    font-size: 2rem;
  } */
  
  .home-container_desktop__p {
    display: none;
  }
  .home-container-rigth__images {
    width: 100%;

    display: flex;
    align-items: flex-end;

  }
  .img-container {
    width: 100%;
  }
  
  @media (min-width: 374px) {
    .home-container__text1 {
      font-size: 3rem;
      text-align: center;

    }
    .home-container__text2 {
      font-size: 3rem;
      text-align: center;
    }
    .home-container__text2 strong {
      font-size: 3rem;
      text-align: center;
    }
    .home-container__p {
      font-size: 20px;
      line-height: 28px;
      font-family: Avenir;
      color: #ffffff;
      text-align: center;
  
    }
    
   
  }
  
  @media (min-width: 768px) {
    .home-container {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      padding: 0 22px;
      background-color: #95bf48;
    }
    .home-container__left {
      text-align: left;
      padding: 4% 0px 0px 5%;
    }
    .home-container__text1 {
      color: white;
      line-height: 84px;
      font-family: Paytone One;
      font-weight: normal;
      font-size: 54px;
    }
    .home-container__text2 strong {
      color: black;
      font-size: 54px;
    }
    .home-container__p {
      display: none;
    }
    .home-container_desktop__p {
      display: block;
      font-family: Avenir;
      font-size: 1rem;
      margin-top: 5%;
      color: #ffffff;

    }
    .home-container-rigth__images {
      width: 100%;
      flex-basis: 200%;
      margin-top: 20px;
      display: flex;
      align-items: flex-end;
    }
    .img-container {
      margin-top: 20%;
    }

  
   
  }
  @media (min-width: 1000) {
    .home-welcome{
      width: 100%;
      height: 30vh;
      background-image: url("https://firebasestorage.googleapis.com/v0/b/woven-catwalk-285723.appspot.com/o/welcome.png?alt=media&token=f111d23e-2789-4517-8e1e-3b2034b3a5b5");
      background-position: right;
      background-size: cover;
  
    }
    .velo{
      background-color: greenyellow;
    }

    
    .home-container_desktop__p {
      font-size: 1.5rem;
    }
    .home-container__text1 {
      font-size: 64px;
    }
    .home-container__text2 {
      font-size: 64px;
    }
    .home-container__text2 strong {
      font-size: 64px;
    }
    .img-container {
      margin-top: 0%;
      width: 80%;
      margin-left: 20%;
    }
  }
/*-------------------------------------------------------------*\
   #SECTION HOME-UNETE
  \*-------------------------------------------------------------*/
.home-container__unete {
  width: 100%;
  font-family: Paytone One;
  font-size: 24px;
  line-height: 34px;
  padding: 48px 10px;
  text-align: center;
  color: #222222;
}
@media (min-width: 374px) {
  .home-container__unete {
    color: black;
    padding: 48px 30px;
  }
}
@media (min-width: 768px) {
  .home-container__unete {
    width: 80%;
    margin-left: 10%;
    font-family: Paytone One;
    font-size: 1.4rem;
    line-height: 65px;
  }
}
@media (min-width: 1024px) {
  .home-container__unete {
    width: 70%;
    margin: 3% 15%;
    font-size: 2rem;
  }
  .input-mid{
    flex-wrap: nowrap;
   }
   .home-welcome-card{
    padding: 5% 5% 2% 5%;
    box-sizing: border-box;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    background-color: white;
    height: auto;
    border-radius: 16px;
    box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  }
  
}

/*-------------------------*\
  #First Step Text
\*-------------------------*/
.steps__section {
  background-color: #F9F9F9;
  width: 100%;
  padding-left: 0%;
  padding-top: 0%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.steps__section__text {
  width: 85%;
}

.steps__section__number {
  color: #95bf48;
  font-family: Paytone One;
  font-size: 56px;
}

.steps__section__tittle {
  color: #000000;
  font-family: Paytone One;
  font-size: 36px;
  margin-bottom: 5%;
}

.steps__section__content {
  color: #595959;
  font-family: Avenir;
  font-size: 16px;
}
/*-------------------------*\
  #First Part Picture
\*-------------------------*/
.steps__section__image {
  position: relative;
  margin-top: 20%;
  margin-bottom: 15%;
  margin-left: 24px;
}

#img-1 {
  width: 200px; 
  margin-right: -25px;
}

#bg-1 {
  position: absolute;
  width: 80%;
  left: -43px;
  top: -59px;
  z-index: -1;
}
/*-------------------------*\
  #Second Step Picture
\*-------------------------*/
.steps__section__02 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: none;
  width: 100%;
  padding-left: 5%;
  padding-top: 5%;
}

#steps__section__image__02 {
  margin-top: 10%;
  margin-bottom: 20%;
  display: flex;
  justify-content: left;
}

#img-2 {
  width: 210px;
  height: 280;
}

#bg-2 {
  position: absolute;
  width: 100%;
  right: 45px;
  bottom: -27px;
  z-index: -1;
}
/*-------------------------*\
  #Third Step Picture
\*-------------------------*/
#steps__section__image__03 {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  margin-bottom: 30%;
}

#img-3 {
  width: 210px;
  margin-left: -7%;
  height: 280;
}

#bg-3-right {
  position: absolute;
  width: 45%;
  right: -30px;
  top: -1px;
  z-index: -1;
}

#bg-3-left {
  position: absolute;
  width: 45%;
  left: -60px;
  bottom: -1px;
  z-index: -1;
}
/*-------------------------*\
  #Media Queries 
\*-------------------------*/
@media screen and (min-width: 475px) {
  /*-------------------------*\
  #First Step Text
\*-------------------------*/
  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .steps__section__text {
    width: 77%;
    margin-left: -10%;
  }
  .steps__section__number {
    font-size: 80px;
    margin-bottom: -2%;
  }
  .steps__section__tittle {
    font-size: 36px;
  }
  .steps__section__content {
    font-size: 24px;
  }
  /*-------------------------*\
  #First Part Picture
\*-------------------------*/
  .steps__section__image {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 17%;
  }
  #img-1{
    width: 50%; 
    
  }
  #bg-1 {
    position: absolute;
    width: 45%;
    left: 50px;
    top: -60px;
    z-index: -1;
  }
  /*-------------------------*\
  #Second Step Picture
\*-------------------------*/
  .steps__section__02 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: none;
    width: 100%;
  }
  #steps__section__image__02 {
    display: flex;
    justify-content: center;
  }
  #img-2 {
    width: 65%;
    margin-left: -5%;
  }
  #bg-2 {
    position: absolute;
    width: 60%;
    right: 40px;
    bottom: -30px;
    z-index: -1;
  }
  /*-------------------------*\
  #Third Step Picture
\*-------------------------*/
  #img-3 {
    width: 50%;
  }
  #bg-3-right {
    position: absolute;
    width: 20%;
    right: 70px;
    top: -1px;
    z-index: -1;
  }
  #bg-3-left {
    position: absolute;
    width: 20%;
    left: 40px;
    bottom: -15px;
    z-index: -1;
  }
}
@media screen and (min-width: 1000px) {
  /*-------------------------*\
    #First Step Text
\*-------------------------*/
  .steps {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .steps__section__text {
    width: 35%;
    margin-left: -1%;
    margin-top: -5%;
  }
  /*-------------------------*\
    #First Part Picture
\*-------------------------*/
  .steps__section__image {
    position: relative;
    margin-top: 10%;
    margin-bottom: -7%;
  }
  #img-1 {
    width: 90%;
    margin-right: 5%;
  }
  #bg-1 {
    position: absolute;
    width: 60%;
    left: -90px;
    top: -110px;
    z-index: -1;
  }
  /*-------------------------*\
    #Second Step Picture
\*-------------------------*/
  .steps__section__02 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    background-color: none;
    margin-top: -3%;
    margin-left: -5%;
  }
  #img-2 {
    width: 100%;
    margin-top: 10%;
    margin-left: 5%;
  }
  #bg-2 {
    position: absolute;
    width: 90%;
    right: -90px;
    bottom: -65px;
    z-index: -1;
  }
  /*-------------------------*\
    #Third Step Picture
\*-------------------------*/
  #steps__section__text__03 {
    margin-top: -20%;
  }
  #img-3 {
    width: 90%;
    margin-top: -18%;
  }
  #bg-3-right {
    position: absolute;
    width: 30%;
    right: -23px;
    top: -70px;
    z-index: -1;
  }
  #bg-3-left {
    position: absolute;
    width: 30%;
    left: -75px;
    bottom: 20px;
    z-index: -1;
  }
}
@media screen and (min-width: 1440px) {
  /*-------------------------*\
  #First Step Text
\*-------------------------*/
  .steps {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .steps__section__text {
    width: 39%;
    margin-left: -3%;
    margin-top: 3%;
  }
  .steps__section__number {
    font-size: 96px;
  }
  .steps__section__tittle {
    font-size: 56px;
  }
  .steps__section__content {
    font-size: 24px;
  }
  /*-------------------------*\
  #First Part Picture
\*-------------------------*/
  .steps__section__image {
    position: relative;
    margin-top: 8%;
    margin-bottom: -7%;
  }
  #img-1,
  #img-3 {
    width: 100%;
  }
  #img-1 {
    margin-right: 5%;
  }
  #bg-1 {
    position: absolute;
    width: 55%;
    left: -125px;
    top: -93px;
    z-index: -1;
  }
  /*-------------------------*\
  #Second Step Picture
\*-------------------------*/
  .steps__section__02 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    background-color: none;
    width: 100%;
    margin-left: -2%;
    margin-bottom: -5%;
    /* border-top: 20vh solid #ffffff;*/
    margin-top: -5%;
  }
  #img-2 {
    width: 100%;
  }
  #bg-2 {
    position: absolute;
    width: 75%;
    right: -80px;
    bottom: -55px;
    z-index: -1;
  }
  /*-------------------------*\
  #Third Step Picture
\*-------------------------*/
  #steps__section__text__03 {
    margin-top: -20%;
  }
  #steps__section__image__03 {
    margin-top: 5%;
    margin-bottom: 20%;
  }
  #img-3 {
    margin-right: 8%;
  }
  #bg-3-right {
    position: absolute;
    width: 30%;
    right: -30px;
    top: -70px;
    z-index: -1;
  }
  #bg-3-left {
    position: absolute;
    width: 30%;
    left: -118px;
    bottom: 30px;
    z-index: -1;
  }
}

/*----------------------------------*\
    #Home Card
  \*----------------------------------*/
.homeCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
  margin-bottom: 80px;
}
.homeCard--background {
  position: absolute;
  background-color: #F9F9F9;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
}
.card {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: 80%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(204, 204, 204, 0.25);
  flex-direction: column;
  border-radius: 24px;
}

.card__title {
  font-family: "Paytone One", sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 50px;
  color: #222222;
  margin-top: 5%;
  font-style: normal;
}

.card__content {
  font-family: AvenirRoman;
  font-weight: normal;
  font-size: 16px;
  color: #595959;
  padding: 3% 8%;
  font-style: normal;
}

.numbersContainer {
  display: flex;
  justify-content: space-around;
  align-content: center;
}

.Container__element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  text-align: center;
}

.elment__number {
  font-family: AvenirBlack;
  font-style: normal;
  font-weight: bold;
  font-size: 68px;
  line-height: 68px;
  color: #666e6c;
}

.elemnt__text {
  font-family: Avenir;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.form__button,
.ant-btn {
  font-family: Avenir;
  align-self: center;
  align-items: center;
  margin-bottom: -20px;
  margin-top: 5%;
}
.form__button:hover {
  background: #799b3b !important;
}


.container_images {
  position: relative;
}

.ellipse {
  position: relative;
  left: 40%;
}

.line {
  width: 80%;
  height: 30%;
}

/*----------------------------------*\
 #images and numbers
\*----------------------------------*/
@media (max-width: 767px) {
  .container_images {
    display: block;
  }

  .numbersContainer {
    display: none;
  }
}

@media (min-width: 1024px) {
  /*----------------------------------*\
 #Images
\*----------------------------------*/
  .container_images,
  .ellipse,
  .line {
    display: none;
  }
  .home-container__left {

  }
  .homeCard{
    margin-top: -160px;
  }
}

/*-------------------------*\
  #Footer
\*-------------------------*/
.homeFooter {
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
}

.Container__element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  text-align: center;
}

.logo_image {
  width: 240px;
  height: 91.51px;
  align-self: center;
  justify-self: center;
}

.container_images {
  position: relative;
}

.ellipse {
  position: relative;
  left: 40%;
}

.line {
  width: 80%;
  height: 30%;
}
.fab {
  font-size: 30px;
  margin: 3%;
  color: #595959;
  cursor: pointer;
}
.fab:hover{
  color:#7ea13d;
  transition: all 1s ease;
}
/*----------------------------------*\
   #images and numbers
  \*----------------------------------*/
@media (max-width: 767px) {
  .container_images {
    display: block;
  }
  .numbersContainer {
    display: none;
  }
  .line {
    height: 3px;
  }
  .ellipse {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
}
@media (min-width: 767px) {
  .container_images {
    display: none;
  }

  .line {
    height: 3px;
  }
  .ellipse {
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
}

@media (min-width: 1440px) {
  /*----------------------------------*\
   #Images
  \*----------------------------------*/
  .container_images,
  .ellipse,
  .line {
    display: none;
  }
  .numbersContainer {
    display: flex;
  }
}

/*-------------------------*\
    #Footer
  \*-------------------------*/
.homeFooter {
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  padding: 40px 0%;
  display: flex;
  text-align: center;
}

.homeFooter--section1,
.homeFooter--section2 {
  display: flex;
  flex-direction: column;
}

.homeFooter__title {
  font-family: AvenirRoman;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #595959;
}

.homeFooter__paraph {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  color: rgba(89, 89, 89, 0.65);
  font-family: AvenirRoman;
  padding-bottom: 12px;
}

.homeFooter--section2 {
  padding-top: 48px;
}
.homeFooter--section3 {
  display: flex;
  flex-direction: column;
}
.homeFooter--section4 {
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  padding: 0 10%;
  font-size: 14px;
}
.section4__p {
  font-size: 12px;
  color: rgba(89, 89, 89, 0.65);
    padding: 1.2%;
}

/*----------------------------------*\
  #Tablet
  \*----------------------------------*/
@media (min-width: 767px) {
  /*----------------------------------*\
    #Footer
    \*----------------------------------*/
  .homeFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    text-align: left;
  }
  .homeFooter--section1,
  .homeFooter--section2,
  .homeFooter--section3 {
    padding-top: 2%;
  }
  .homeFooter--section1 {
    display: flex;
    flex-direction: column;
  }
   .homeFooter--section4 {
    align-content: center;
  }


  .fab {
    margin: 10px;
  }
}

/*----------------------------------*\
  #Desktop
  \*----------------------------------*/
@media (min-width: 1440px) {
  /*----------------------------------*\
    #Footer
  \*----------------------------------*/
  .section4__p {
    font-size: 14px;
  }
  .homeFooter--section2,
  .homeFooter--section3 {
    padding-top: 2%;
  }
  .homeFooter--section1 {
    padding-bottom: 1%;
  }
  .fab {
    margin: 10px;
  }
  .home-container__text1 {
    text-align: left;

  }
  .home-container__text2 {
    text-align: left;
  }
  .home-container__text2 strong {
    text-align: left;
  } 
  .home-container_desktop__p {
    font-size: 24px;
  }
}
