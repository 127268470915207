/*------------------------------------*\
  #general
\*------------------------------------*/
.polls__title {
  font-family: AvenirRoman;
  font-size: 48px;
  color: #323232;
  display: flex;
  align-items: center;
}
.polls__content {
  font-family: AvenirBook;
  font-size: 18px;


}
.polls__content__dos {
  font-family: AvenirBook;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -24px;
}
.polls__title--points {
  color: #95bf48;
  font-weight: 800;
}

/*------------------------------------*\
  #Table
\*------------------------------------*/
.table__save{
  font-family: AvenirBook;
  font-size: 18px;
  color: #95BF48;
  cursor: pointer;
  margin-right: 20px;
}

.table__cancel{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  cursor: pointer;
}

.table__actions{
  display: flex;
  gap:10px;
  justify-content: center;
  align-items: center;
}

.table__send {
  font-family: AvenirBook;
  font-size: 18px;
  color: #009fcf;
  text-decoration: underline;
  margin-right: 20px;
  cursor: pointer;
}

.send__inactive {
  font-family: AvenirBook;
  font-size: 18px;
  color: #c4c4c4;
  text-decoration: underline;
  margin-right: 20px;
}

.table__edit{
  font-family: AvenirBook;
  font-size: 18px;
  color: #95BF48;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.table__edit:hover {
  color: #7ea13d;
}
/*------------------------------------*\
  #TABLE PANELISTS
\*------------------------------------*/
.panelist_image{
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
.survey--image{
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
.polls__filter{
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 16px rgba(196, 196, 196, 0.25);
  background: #FFFFFF;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 0px 0px 0px 15px;
}
.polls__search-filter{
  display: flex;
  justify-content: center;
  align-items: center;
}
.polls__filter_img{
  font-size: 20px;
}
.polls__filter_img_active{
  color: #95BF48;
  font-size: 20px;
}
.surveys__button-container{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.surveys__send-button{
  margin: 20px 0px;
}
.surveys__icon-send{
   margin-right: 15px;
}


.table__panelists{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.section__filter{
  /* height: 300px;  */
  min-width: 266px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(196, 196, 196, 0.25);
  border-radius: 8px;
  margin-left: 16px;
  padding:25px;
}
.section__filter-title{
  font-size: 16px;
  font-family: AvenirRoman;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
}
.section__filter-gender{
  font-size: 16px;
  font-family: AvenirRoman;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5%;
}
.modal__title{
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 24px;
  color: #000000;
}
.modal__content{
  font-family: AvenirBook;
  font-size: 14px;
  line-height: 19px;
  color: #595959;
  margin: 30px 0;
}
.modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.modal__button--cancel {
  background: none;
  width: 105px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 14px;
  color: #c4c4c4;
  margin: 0 8px;
}
.modal__button--send {
  border: none;
  width: 105px;
  height: 32px;
  background: #95bf48;
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 14px;
  color: #ffffff;
  margin: 0 8px;
  cursor: pointer;
}
/*------------------------------------*\
  #TABLE PANELISTS
\*------------------------------------*/
@media (max-width: 768px) {
  .table__panelists{
    flex-direction: column-reverse;
  }
  .section__filter{
    margin-left: 2%;
    margin-bottom: 3%;
  }
}

.plusButton {
  border-radius: 50%;
  background-color: #7ea13d;
  font-size: 2.5rem;
  color: white;
  margin:0;
  width: 50px;
  height: 50px;
  border:none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s all;
}

.plusButton:hover{
  box-shadow: 2px 2px 2px 1px darkgrey;
  background-color: #95BF48;
}

.plusButton:active{
  background-color: yellowgreen;
  box-shadow: 0px 0px 1px 1px darkgrey;
}
