/*------------------------------------*\
  #Profile title 
\*------------------------------------*/
.profile__title {
  font-family: AvenirRoman;
  font-size: 48px;
  color: #323232;
  margin-bottom: 20px;
}
.myProfile {
  display: flex;
  margin-bottom: 50px;
}
/*------------------------------------*\
  #left side
\*------------------------------------*/
/*------------------------------------*\
  #card
\*------------------------------------*/
.myProfile--left {
  min-width: 276px;
}
.left__card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px;
}
.left__card__image-container {
  width: 186px;
  height: 186px;
  position: relative;
}
.left__card__spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff8f;
}
.left__card__image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.left__card__input {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.left__card__icon {
  color: rgba(150, 150, 150, 0.32);
  font-size: 32px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 10px;
}
.left__card__icon:hover {
  color: rgba(150, 150, 150, 0.92);
  cursor: pointer;
}
.left__card__name {
  font-family: AvenirRoman;
  font-size: 18px;
  color: #323232;
  margin-top: 32px;
}
.left__card__email {
  font-family: AvenirBook;
  font-size: 14px;
  color: #959595;
}
.left__card__content {
  width: 100%;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 2px solid rgba(196, 196, 196, 0.2);
  display: flex;
  flex-direction: column;
}
.card__content--element {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  font-family: AvenirRoman;
  font-size: 16px;
  color: #323232;
}
.card__content--element--number {
  font-weight: 800;
  line-height: 22px;
  color: #95bf48;
}
/*------------------------------------*\
  #Right side
\*------------------------------------*/
.myProfile--right {
  padding: 0 3%;
  width: 100%;
}

/*------------------------------------*\
  #Information
\*------------------------------------*/

.information {
  width: 100%;
}
.information--container {
  padding: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  border-radius: 4px;
  min-height: 135px;
  margin: 8px;
}
.information__flex {
  display: flex;
}
.information__name {
  font-family: AvenirBook;
  font-weight: 800;
  font-size: 30px;
  color: #323232;
}
.name--have {
  font-family: AvenirBook;
  font-weight: 500;
}
.name__points {
  color: #95bf48;
}
.badge {
  font-family: AvenirBook;
  font-size: 16px;
  margin-top: 8px;
}
.badge__image {
  width: 24px;
  margin-right: 10px;
}
.information__title {
  font-family: AvenirBook;
  font-size: 16px;
  margin-bottom: 19px;
}
.surveys-status {
  font-family: AvenirBook;
  font-size: 14px;
  text-align: center;
  color: #959595;
  margin: -20px 0 20px 0;
}
.information__content {
  font-family: AvenirBook;
  font-size: 24px;
  line-height: 33px;
  color: #323232;
}
/*------------------------------------*\
  #Rewards
\*------------------------------------*/
.table__data {
  font-family: AvenirBook;
  font-size: 18px;
  line-height: 25px;
  color: #595959;
}
.table__points {
  font-family: AvenirBook;
  font-weight: 800;
  font-size: 18px;
  color: #af7628;
}
.divider{
  margin: 48px 0 24px 0;
  opacity: .2;
}
/*------------------------------------*\
  #empty rewards
\*------------------------------------*/
.emptyText {
  font-family: AvenirBook;
  font-size: 16px;
  color: #323232;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emptyText a {
  color: #000;
  font-weight: 800;
}
.medallas{
  width: 60%;
  margin-left: 20%;
}

@media (max-width: 775px) {
  .myProfile {
    flex-direction: column;
    align-items: center;
  }
  .myProfile--right {
    padding: 30px 0;
  }
  .medallas{
    width: 80%;
  margin-left: 10%;
  }
}
