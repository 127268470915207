/*------------------------------------*\
#Info
\*------------------------------------*/
.info__profile__container__top{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.info__profile__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 20px;
}
.info__profile__title {
    font-family: AvenirRoman;
    font-size: 30px;
    color: #323232;
    margin: 0;
}
.info__image__container{
    width: 100px;
    height: 100px;
    margin: 0px;
    padding: 0px;
}
.info__image__profile{
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

/*------------------------------------*\
# Tab Information
\*------------------------------------*/
.tab__information {
    width: 100%;
}
.tab__information__container {
    padding: 20px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
    border-radius: 4px;
    min-height: 135px;
    margin: 8px;
}
.tab__information__general__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;

}
.tab__information__general__header__title{
    margin: 0;
}
.tab__information__general__type{
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    width: 200px;
    color:#898989;
    font-family: AvenirRoman;
}

.tab__information__general__type p{
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: lighter;
}
.tab__information__general__body{
    display: flex;
    flex-direction: row;
    min-width: min-content;
}
.tab__information__general__data p {
    font-size: 15px;
    margin-bottom: 10px;
    min-width: 300px;
    font-family: AvenirRoman;
}


/* @media (max-width: 575px){
    .tab__information__general__body{
        overflow-x: scroll;
    }
} */