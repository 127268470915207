/*------------------------------------*\
  #separator between header and main content
\*------------------------------------*/
.productDetails--content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  min-height: 93vh;
}
.alligner {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
}
/*------------------------------------*\
  #left side
\*------------------------------------*/
.left {
  width: 50%;
  padding: 0 0 0 5%;
}
.left__gallery {
  width: 100%;
  position: relative;
}
/*------------------------------------*\
  #Gallery
\*------------------------------------*/
.gallery__icon {
  position: absolute;
  font-size: 30px;
  color: #d9d9d9;
  top: 44%;
  cursor: pointer;
}
.gallery__icon:hover {
  color: #535353;
}
.gallery--left {
  left: 3%;
}
.gallery--right {
  right: 3%;
}
.gallery__image {
  width: 100%;
  object-fit: contain;
  height: 450px;
  background: white;
}
.gallery__thumbnails {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.thumbnail {
  width: 108px;
  height: 80px;
  margin: 18px 18px 0 0;
  object-fit: cover;
  cursor: pointer;
}
.thumbnail--selected {
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  border: 2px solid #eb9b41;
}
/*------------------------------------*\
  #right side
\*------------------------------------*/
.right {
  width: 50%;
  font-family: AvenirRoman;
  padding: 0 5%;
}

.right__title {
  font-family: AvenirBlack;
  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
}
.right__points {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #009fcf;
  margin: 8px 0;
}
.right__description {
  font-size: 16px;
  line-height: 22px;
  margin: 16px 0;
}
.right__content {
  font-size: 16px;
  line-height: 22px;
}

.right__button {
  border: none;
  cursor: pointer;
  width: 240px;
  height: 48px;
  background: #95bf48;
  border-radius: 4px;
  margin: 30px 0;
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}
.right__button:hover {
  background: #799b3b;
}
/*------------------------------------*\
  #movil size
\*------------------------------------*/
@media (max-width: 767px) {
  .alligner {
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column-reverse;
    padding: 30px 0;
  }
  .right,
  .left {
    width: 100%;
    padding: 2% 10%;
  }
}
