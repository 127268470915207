/*------------------------------------*\
  #summary
\*------------------------------------*/
.summary__title {
  font-family: AvenirRoman;
  font-size: 48px;
  line-height: 66px;
  color: #323232;
  padding: 0 2%;
}
.summary {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
/*------------------------------------*\
  #content left
\*------------------------------------*/
.summary--left {
  width: 50%;
  padding: 0 2%;
}
.summary--bullet{
  color: #95bf48;
}
.summary--left__title {
  font-family: AvenirRoman;
  font-size: 24px;
  line-height: 33px;
  color: #95bf48;
}
.summary--left__content {
  font-family: AvenirRoman;
  font-size: 16px;
  margin: 20px 0;
  color: #595959;
}
/*------------------------------------*\
  #content chart
\*------------------------------------*/
.chart {
  width: 100%;
}
.chart__title {
  font-family: AvenirBook;
  color: #323232;
  font-size: 24px;
  margin: 30px 0;
}
.chart--flex {
  display: flex;
  justify-content: space-around;
}
.pie-chart {
  width: 46%;
  position: relative;
}
.pie-chart__text {
  position: absolute;
  top: 56%;
  width: 100%;
  text-align: center;
  font-family: AvenirBook;
  color: #000;
  font-size: 14px;
}
/*------------------------------------*\
  #content right
\*------------------------------------*/
.summary--right {
  width: 50%;
  padding: 0 2%;
}
.summary--right__content {
  font-family: AvenirRoman;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 33px;
  color: #323232;
}
.table {
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(196, 196, 196, 0.25);
  border-radius: 8px;
  margin: 15px 0 20px 0;
}
@media (max-width: 810px) {
  .summary--right {
    width: 100%;
    padding: 30px 2%;
  }
  .summary--left {
    width: 100%;
    padding: 0 2%;
  }
}
