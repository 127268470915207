/*------------------------------------*\
  #General styles
\*------------------------------------*/
.sign {
  min-height: 100vh;
  position: relative;
}

/*------------------------------------*\
  #Left Slider
\*------------------------------------*/

.slider {
  width: 50%;
  overflow: hidden;
  background: rgba(50, 50, 50, 0.32);
  position: absolute;
  height: 100%;
  top: 0%;
  left: 0;
}

.slider__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slider__element {
  position: relative;
  height: 100%;
  width: 100%;
}
.slider__content {
  position: absolute;
  bottom: 180px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 50px;
}
.slider__content p {
  font-family: AvenirBlack;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 0 10%;
}
.cover{
  background-color: #222222;
  width: 100%;
  height: 100%;
}
/*------------------------------------*\
  #right Side
\*------------------------------------*/

.side-right {
  position: relative;
  width: 50%;
  margin-left: 50%;
}

.side-right__logo {
  position: absolute;
  top: 3%;
  left: 8%;
  width: 140px;
}

/*------------------------------------*\
  #signup and signin general styles
\*------------------------------------*/

.signupform,
.signinform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #595959;
  padding: 50px 10% 30px 10%;
  min-height: 100vh;
}

.signupform {
  padding-top: 80px;
}

.side-right__Title {
  font-family: "Paytone One", sans-serif;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
}
.side-right__content {
  font-family: AvenirRoman;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 28px 0;
}

/*------------------------------------*\
  #FORM
\*------------------------------------*/

.form {
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__input-container {
  width: 100%;
  position: relative;
}
.form__label {
  font-family: "ABeeZee", sans-serif;
  font-size: 12px;
  line-height: 21px;
}
.form__input {
  width: 100%;
  height: 48px;
  border: 1px solid rgba(196, 196, 196, 0.95);
  border-radius: 4px;
  padding: 0 10px;
}

.form__accept-terms {
  font-family: "ABeeZee", sans-serif;
  font-size: 12px;
  line-height: 21px;
  width: 100%;
}
.accept-terms_checkbox {
  margin: 0px 7px;
  cursor: pointer;
}
.accept-terms--link {
  text-decoration: underline #009fcf;
  color: #009fcf;
  margin: 0 5px;
}
.accept-terms--link:hover {
  color: #047da1;
}

.form__button {
  height: 40px;
  line-height: 25px;
  font-family: AvenirRoman;
  margin: 30px 0 15px 0;
  width: "280px";
}

.form__button:hover {
  background: #799b3b !important;
}
.form__container__button {
  width: 100%;
  min-width: 180px;
  max-width: 350px;
}
.if-account {
  width: 100%;
  text-align: center;
  font-family: AvenirBook;
  font-size: 16px;
  line-height: 19px;
}

.if-account--link {
  text-decoration: underline #009fcf;
  transition: all ease 0.5s;
  color: #009fcf;
}
.if-account--link:hover {
  color: #047da1;
}
.if-password {
  width: 100%;
  text-align: center;
  font-family: AvenirBook;
  font-size: 16px;
  line-height: 23px;
}
.if-password:hover {
  text-decoration: underline;
}
/*------------------------------------*\
  #MOVILE MEDIA QUERY
\*------------------------------------*/

@media (max-width: 767px) {
  .slider {
    display: none;
  }
  .side-right {
    width: 100%;
    margin: 0;
  }
  .side-right__content {
    margin: 0;
  }
  .form__button {
    margin: 7px 0;
  }
  .signupform {
    padding: 80px 10% 50px 10%;
  }
  .signinform {
    padding: 50px 10% 20px 10%;
  }
  .side-right__logo {
    top: 2%;
    left: 5%;
  }
}
