/*-------------------------*\
  #Title
\*-------------------------*/
.profileform__title {
    font-family: AvenirRoman;
    font-size: 24px;
    color: #323232;
    display: flex;
    align-items: center;
    padding-top: 3%;
  }

.profileform__description {
    font-family: AvenirBook;
    font-size: 16px;
    align-self: start;
    margin: 2% 0%;
    width: 80%;
  }

  .profileform__form{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .profileform__item{
    font-family: AvenirBook;
    display: flex;
    font-size: 14px;
    width: 75%;
  }  

  .profileform__item__age{
    display: flex;
    font-family: AvenirBook;
    font-size: 14px;   
  }

  .profileform__item__calendar{
    font-family: AvenirBook;
    font-size: 14px;
    width: 67.3%;   
  }

/*-------------------------*\
  #Button
\*-------------------------*/
.profileform__button{
    height: 40px;
    line-height: 25px;
    font-family: Avenir;
    margin: auto;    
}

  @media (max-width: 768px) {
    .profileform__description {
      width: 100%;
      text-align: justify;
    }
    .profileform__form{
      align-content: center;
      width: 100%;
    }
    .profileform__item{
      width: 100%;
      text-align: justify;
    }  
    .profileform__button{
      margin: auto;
  }
  .profileform__item__calendar{
    width: 100%;   
  }
  }