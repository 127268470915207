/*------------------------------------*\
  #Banner
\*------------------------------------*/
.banner {
  width: 100%;
  height: 293px;
  overflow: hidden;
  position: relative;
  background: url(../../assets/quest-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* this selector contain gradient modification */
.banner__content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Paytone One", sans-serif;
  font-size: 36px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
}

/*------------------------------------*\
  #Questions
\*------------------------------------*/
.questions {
  padding: 50px 10%;
}
.questions_question {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #595959 !important;
  font-family: AvenirRoman;
}

.questions__answer {
  font-size: 14px;
  line-height: 25px;
  color: #595959;
  font-family: AvenirBook;
}
.signin{
  font-family: AvenirBlack;
  font-style: oblique;
}
/*-------------------------*\
  #Media Queries Mobile
\*-------------------------*/
@media screen and (max-width: 767px) {
  .banner {
    height: 300px;
    margin-top: -64px;
    z-index: -1;
  }
}
