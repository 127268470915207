/*------------------------------------*\
  #general style
\*------------------------------------*/
.admin {
  height: 100%;
  min-height: 100vh;
}

/*------------------------------------*\
    #sider menu
  \*------------------------------------*/

.sider {
  position: relative;
  font-family: AvenirRoman;
  font-size: 18px;
  line-height: 25px;
}

.sider__logo {
  width: 120px;
  margin: 21px;
}
.sider__menu__element {
  margin-right: 10px;
  font-size: 16px;
}
.sider__menu__text {
  color: #ffffff;
}
.sider__menu__text:hover {
  color: #95bf48;
}
/*------------------------------------*\
    #header
  \*------------------------------------*/

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: AvenirRoman;
  font-size: 15px;
  color: #323232;
  line-height: 20px;
  margin: 20px 0;
  min-height: 40px;
}
.header__element {
  margin-left: 10px;
  padding: 0 10px;
}
.element--img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
}
.header__element--name {
  font-size: 18px;
  color: #323232;
}
@media (max-width: 575px) {
  .header {
    margin: 10px 0;
  }
}
