/*------------------------------------*\
  #general
\*------------------------------------*/
.panelists__title {
  font-family: AvenirRoman;
  font-size: 48px;
  color: #323232;
  display: flex;
  align-items: center;
}

.panelists__content {
  font-family: AvenirBook;
  font-size: 18px;
  margin: 10px 0;
  
}
.panelists__title--quantity {
  color: #95bf48;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
}
.panelists__title--actions{
  display: flex;
  justify-content: flex-end;
}
.panelist--image {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.reward--delete {
  color: #595959;
  font-size: 20px;
  cursor: pointer;
}
.reward--delete:hover {
  color: rgb(233, 96, 96);
}

.modal__title {
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 24px;
  color: #000000;
}
.modal__content {
  font-family: AvenirBook;
  font-size: 14px;
  line-height: 19px;
  padding: 0 10%;
  margin: 30px 0;
}
.modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.modal__button--cancel {
  background: none;
  width: 105px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 18px;
  color: #c4c4c4;
  margin: 0 8px;
}
.modal__button--cancel:hover {
  border: 1px solid #95bf48;
  color: #95bf48;
}
.modal__button--delete {
  border: none;
  width: 105px;
  height: 40px;
  background: #95bf48;
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
  margin: 0 8px;
  cursor: pointer;
}
.modal__button--delete:hover {
  background: #5d7e20;
}
