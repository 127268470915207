.resetpass {
  min-height: 100vh;
  position: relative;

}

/*------------------------------------*\
  #Left Galery
\*------------------------------------*/

.slider {
  width: 50%;
  overflow: hidden;
  background: rgba(50, 50, 50, 0.32);
  position: absolute;
  height: 100%;
  top: 0%;
  left: 0;
}

.slider__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slider__element {
  position: relative;
  height: 100%;
  width: 100%;
}
.slider__content {
  position: absolute;
  bottom: 180px;
  width: 100%;
  height: 100%;
  background: rgba(196, 196, 196, 0.16);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 50px;
}
.slider__content p {
  font-family: AvenirBlack;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  padding: 0 10%;
}
/*------------------------------------*\
  #Right Content
\*------------------------------------*/
.content-right {
  position: relative;
  width: 50%;
  margin-left: 50%;
}
.content-right__logo {
  position: absolute;
  top: 3%;
  left: 8%;
  width: 140px;
}
.content-right__resetPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #595959;
  padding: 100px 10% 30px 10%;
  min-height: 100vh;
}
.content__Title {
  font-family: "Paytone One", sans-serif;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
}
.content_description {
  font-family: AvenirRoman;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 28px 0;
}
/*------------------------------------*\
  #Form
\*------------------------------------*/
.form {
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form__input {
  width: 100%;
  position: relative;
}
.form__label {
  font-family: AvenirBook;
  font-size: 12px;
  line-height: 21px;
}
.form__input-container {
  width: 100%;
  height: 48px;
  border: 1px solid rgba(196, 196, 196, 0.95);
  border-radius: 4px;
  padding: 0 10px;
}
.form__buttonContainer {
  width: 100%;
  min-width: 180px;
  max-width: 350px;
}
.form__button {
  height: 40px;
  line-height: 25px;
  font-family: AvenirRoman;
  margin: 30px 0 15px 0;
  width: "280px";
}
.form__button:hover {
  background: #799b3b !important;
}

.count {
  width: 100%;
  text-align: center;
  font-family: AvenirBook;
  font-size: 16px;
  line-height: 19px;
}
.count-link {
  text-decoration: underline #009fcf;
  transition: all ease 0.5s;
  color: #009fcf;
}
.count-link:hover {
  color: #047da1;
}
/*------------------------------------*\
  #Media Mobile
\*------------------------------------*/
@media (max-width: 767px) {
  .slider {
    display: none;
  }
  .content-right {
    width: 100%;
    margin: 0;
  }
  .content_description {
    margin: 5px 0px 25px 0px;
  }
  .form__button {
    margin: 7px 0;
  }
  .content-right__resetPassword {
    padding: 50px 10% 20px 10%;
  }
  .content-right__logo {
    top: 2%;
    left: 5%;
  }
}
