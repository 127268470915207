@import "~antd/dist/antd.less";
/*------------------------------------*\
  #force antd styles
\*------------------------------------*/
.ant-carousel,
.slick-list,
.slick-track,
.ant-carousel .slick-slide img,
.ant-carousel img,
.slick-slide.slick-active.slick-current > div,
.slick-slide > div {
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #95bf4852;
  color: #fff;
}

.ant-table-thead > tr > th {
  font-family: AvenirBook;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  background: #ffffff;
  color: #323232;
}

.table__data {
  font-family: AvenirBook;
  font-size: 18px;
  line-height: 25px;
  background: #ffffff;
  font-weight: 800;
}
.table__light-data {
  font-family: AvenirBook;
  font-size: 18px;
  line-height: 25px;
  background: #ffffff;
}
.highlight--data {
  color: #007fa6;
  font-weight: 800px;
}
.table__product-img {
  padding: 0 0 0 16px !important ;
  width: 40px;
  height: 40px;
}
a,
.ant-menu-item a {
  color: unset;
}
a:hover {
  color: unset;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-below.ant-layout-sider-zero-width
  > .ant-layout-sider-zero-width-trigger {
  top: 10px;
  height: 40px;
  background: none;
  color: black;
  font-size: 28px;
  right: -46px;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: rgba(255, 255, 255, 0.5);
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-below
  > .ant-layout-sider-zero-width-trigger {
  top: 0px;
  background: #323232;
  color: white;
  font-size: 28px;
  width: 44px;
  right: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 79px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: unset;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-below.ant-layout-sider-zero-width
  + section.ant-layout {
  opacity: 1;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-below
  + section.ant-layout {
  opacity: 0.4;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 11px 12px;
  font-family: AvenirBook;
  font-weight: 600;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #dfdfdf;
}
.ant-upload.ant-upload-select-picture-card{
  width: 100%;
  height: 32px;
}
.ant-btn-primary[disabled]{
  background-color: #c4c4c4;
  color: white;
}
.ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}
.ant-btn-sm{
  font-family: AvenirBook;
}

.ant-form label{
  font-size: 16px;
  color:#595959;}
  
@media (max-width: 430px) {
  .ant-table-thead > tr > th {
    font-size: 15px;
  }
  .table__light-data {
    font-size: 13px;
  }
}

@media (max-width: 575px) {
  .ant-layout-header {
    padding: 0 10px 0 0;
  }
}
.ant-input-search-icon::before {
  position: unset;
}

@primary-color: #95BF48;@text-color: #595959;@padding-lg: 24px;@padding-md: 16px;@padding-sm: 12px;@padding-xs: 8px;@padding-xss: 4px;@control-padding-horizontal: @padding-sm;@control-padding-horizontal-sm: @padding-xs;@margin-lg: 24px;@margin-md: 16px;@margin-sm: 12px;@margin-xs: 8px;@margin-xss: 4px;@height-base: 32px;@height-lg: 40px;@height-sm: 24px;@btn-link-hover-bg: #dd2525;