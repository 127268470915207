/*----------------------------------*\
  #Seccion quienes somos imagenes y texto
\*----------------------------------*/
.aboutus {
  background: #f2f2f2;
}
.aboutus__title {
  font-family: "Paytone One", sans-serif;
  width: 100%;
  font-size: 24px;
  line-height: 50px;
  color: #323232;
  margin-top: 40px;
  text-align: center;
  padding: 1%;
}
.aboutus__title--color {
  color: #95bf48;
}

.aboutus__description {
  font-family: AvenirRoman;
  font-size: 18px;
  text-align: center;
  color: #595959;
  width: 100%;
  padding: 0 20%;
  margin: 20px 0;
}
.images-container {
  display: flex;
  max-width: 1200px;
  width: 90%;
  margin: 30px auto;
  margin-bottom: 0;
  height: 408px;
}
#image-left {
  height: 100%;
  border-radius: 10px;
  width: 30%;
  object-fit: cover;
}
.images--middle-container {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
}
#image-middle-top {
  width: 100%;
  height: 192px;
  object-fit: cover;
  margin-bottom: 12px;
  border-radius: 10px;
}
#image-middle-bottom {
  width: 100%;
  height: 192px;
  object-fit: cover;
  margin-top: 12px;
  border-radius: 10px;
}
.images--right-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#image-right-top {
  width: 100%;
  height: 123px;
  object-fit: cover;
  margin-bottom: 12px;
  border-radius: 10px;
}
#image-right-bottom {
  width: 100%;
  height: 260px;
  object-fit: cover;
  margin-top: 12px;
  border-radius: 10px;
}

.aboutus__container-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15%;

}
.aboutus__container-card--background {
  position: absolute;
  background: #f2f2f2;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  z-index: -1;
}
.aboutus__card {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: 80%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(204, 204, 204, 0.25);
  flex-direction: column;
  border-radius: 24px;
  margin-top: 10%;
}
.elements-container{
  display: flex;
  flex-direction: column;
}
.card__element__title{
  font-family: AvenirRoman;
font-weight: 800;
font-size: 20px;
text-align: center;
 color: #95BF48;
}
.card__element__description{
  font-family: AvenirRoman;
font-size: 14px;
line-height: 24px;
text-align: center;
color: #595959;
padding: 3%;
margin-bottom: 20px;
}
.card__element{
  margin: 2%;
  margin-bottom: 5%;
}
.card__title{
  font-family:"Paytone One", sans-serif;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  color: #323232;
  padding-top: 20px;
}
.card__description{
  align-self: center;
  font-family: AvenirRoman;
  font-size: 16px;
  color: #595959;
  width: 75%;
  padding-top: 24px;
  padding-bottom:20px;
}
.card__element__icon{
  font-size: 30px;
  color: black;
}

@media (min-width: 768px) {
  .card__element:nth-child(1) .card__element__title {
    color: #95bf48;
    font-size: 24px;
  }
  .card__element:nth-child(2) .card__element__title {
    color:  #009FCF;
    font-size: 24px;
  }
  .card__element:nth-child(3) .card__element__title {
    color:  #EB9B41;
    font-size: 24px;
  }
  .elements-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .card__description{
    width:66%;
  }
  .aboutus__container-card--background{
    height: 50%;
  }
  .aboutus__card{
    margin-top: 5%;
  }
  .aboutus__title{
    font-size: 36px;
  }
  .card__title{
    font-size: 36px;
  }
  .aboutus__container-card{
    margin-bottom: 8%;
  }
  .card__element__description{
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .images--right-container {
    display: none;
  }
  #image-left {
    width: 50%;
  }
  .images--middle-container {
    width: 50%;
    margin: 0;
    margin-left: 24px;
  }
}
