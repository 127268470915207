.container{
    background-color: #E5E5E5;
    width: 100%;
    color: #595959;
    text-align: center;
    position: absolute;
    z-index: -1;
    top: 0;
}
.title{
    margin-top: 90px;
    font-family: Paytone One;
    font-size: 19px;
}
.container-text{
    text-align: justify;
    font-family: AvenirRoman;
    margin: 10% 10%;
    position: relative;
}
.container--textPragraphs{
    margin-top: 5%; 
}
/* Desktop */
@media (min-width: 768px){
    .container{
       position: relative;
       min-height: 100%; 
       padding-bottom: 3%;
    }
    .title{
        margin-top: 0px;
        padding: 3%;
        font-size: 36px;
    }
    .container-text{
        margin: 0% 8% 0% 8%;
        font-size: 18px;
    }
    .container--textPragraphs{
        margin-top: 2%;
    }
}