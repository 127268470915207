.rewards__form,
.reward__content {
  padding: 5%;
}
.rewardform__title {
  font-family: AvenirBook;
  font-size: 48px;
  color: #323232;
}
.rewars__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.rewardform__p {
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  margin-top: -3%;
}
.form__image{
  display: flex;
  flex-direction: row;
}
.image{
  max-width: 120px;
  width: 100%;
  height: 90px;
  margin: 0 12px;
  object-fit: contain;
}
.form__label {
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959
}
.form__images-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.form__images {
  object-fit: contain;
  width: 207px;
  height: 106.5px;
}
.form__input {
  margin-left: 30px;
}

.label {
  text-align: left;
  font-family: AvenirBook;
font-size: 18px;
color: #595959;
}
.reward__title {
  font-family: AvenirRoman;
  font-size: 48px;
  color: #323232;
}
.printReward {
  position: relative;
  left: 6%;
  width: 100%;
  padding: 3%;
}
.reward__subtitle {
    font-family: AvenirBook;
    font-size: 18px;
    color: #595959;
    margin-bottom: 35px;
    position: relative;
    margin-top: -3%;
    margin-left: -9%;
}
.subtitle {
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  margin-bottom: 25px;
  margin-top: -3%;
}

.row__first_d {
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  position: relative;
  left: 0px;
}
.row__first{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
}
.row__first_p{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
}
.row__first_c{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
}
.row__first_f{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
}
.row__second{
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left: 0px;
  padding: 5px;
}
.row__second_d {
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left: 0px;
  padding: 5px;
}
.row__second_p {
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left: 0px;
  padding:5px;
}
.row__second_c {
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left:0px;
  padding: 5px;
}
.form__image--container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 50%;
  flex-wrap: wrap;
}
.row__second_f {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.second__image {
  max-width: 120px;
  width: 100%;
  height: 90px;
  margin: 0 10px;
  object-fit: contain;
}
.editButton-container {
  width: 90%;
  max-width: 775px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0;
}
.editButton {
  font-family: AvenirBook;
  font-weight: 800;
  font-size: 18px;
  width: 158px;
  height: 40px;
  background: none;
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  cursor: pointer;
  margin: 35px 0 30px 0;
  transition: all 0.2s ease-in-out;
}
.editButton:hover {
  background: #95bf48;
  color: #fff;
  border: none;
}
.form__save{
background: #95BF48;
border: 1px solid #95BF48;
box-sizing: border-box;
border-radius: 20px;
}
.containerButton{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
/*custom inpu file*/
.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before{
  position: absolute;
  left: 0px;
	content: "+ Agrega hasta 4 fotografías";
    color: #CCCCCC;
    background:  #f9f9f9;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    border-style: dashed;
    text-align: center;
}
.imagePreview{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
}
.imageprew{
  max-width: 120px;
  width: 100%;
  height: 90px;
  margin: 0 12px;
  object-fit: contain;
  margin-bottom: 10px;
}
.printReward--row{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 90%;
  margin: 19px 0;
}

@media (min-width: 767px) {
  .rewards__form,
  .reward__content {
    padding: 0%;
  }
  .form__newReward{
    padding: 0%;
  }
  .form__delete-image{
    position: relative;
    z-index: 1;
    left: -20px;
    top: -25px;
  }
  .printReward--row{
    flex-direction: row;
  }
  .row__second{
    margin-left: 15px;
  }
.row__second_d {
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left: -14px;
  padding: 5px;
}
.row__second_p {
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left: 24px;
  padding: 5px;
}
.row__second_pieces {
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left: 30px;
  padding: 5px;
}
.row__second_c {
  width: 100%;
  border: 1px solid #d4cdcd;
  border-radius: 2px;
  font-family: AvenirBook;
  font-size: 18px;
  color: #323232;
  margin-left: 1px;
  padding: 5px;
}

.row__first_d {
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  position: relative;
  left: -29px;
}
.row__first{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
}
.row__first_pieces{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  position: relative;
  left: 10px;
}
.row__first_c{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  position: relative;
  right: 10px;
}
.row__first_f{
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  position: relative;
  right: 20px;
}
.printReward--row--images{
  border: none;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.subtitle {
  font-family: AvenirBook;
  font-size: 18px;
  color: #595959;
  margin-bottom: 25px;
  margin-top: 10px;
}
.addTarjet{
  margin-left: 30%;
}
}
.addTarjet{
  position: absolute;
  border: unset;
background-color: #f9f9f9;
color: #595959;
font-size: 16px;
font-family: AvenirBook;
  left: 2%;
margin-top: -20px;
}
.containerImgesInput{
  display: flex;
  flex-direction: column;
}
.imagesContainer{
display: flex;
flex-direction: row;
flex-wrap: wrap;
}