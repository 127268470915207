/*-------------------------*\
  #Header Section
\*-------------------------*/
.header {
  position: relative;
  height: 300px;
  background-size: cover;
  background-position: 60%;
  background-repeat: no-repeat;
}

.header_photo {
  position: relative;
  width: 100%;
}

.header_background{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.header_text {
  font-family: Paytone One;
  color: #ffffff;
  font-size: 36px;
  text-align: center;
  margin: 0;
  max-width: 560px;
}
/*-------------------------*\
    #Tab Bar Section
\*-------------------------*/
.bar {
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}
.tabs__container {
  width: 90%;
  margin-bottom: 50px;
  max-width: 1148px;
}

.bar__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}
/*-------------------------*\
    #Product Car Section
\*-------------------------*/
.card {
  font-family: AvenirRoman;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  border-radius: 4px;
  cursor: pointer;
  width: 240px;
  margin: 3% 2%;
  padding: 1% 1%;
  transition: 0.3s;
}

.card:hover {
  box-shadow: 1px 11px 20px rgba(204, 204, 204, 0.4);
  z-index: 1;
  transform: scale(1.05);
}

.card_image {
  width: 100%;
  height: 10vw;
  object-fit: contain;
}

.card_line {
  border: 1px solid rgba(196, 196, 196, 0.2);
  width: 208px;
  margin: 3% 3.5%;
}

.card_product {
  font-size: 16px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.card_overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card_description {
  font-size: 12px;
}

.card_point {
  font-size: 12px;
  color: #009FCF;
  font-weight: 800;
}
/*-------------------------*\
    #Media Queries 
\*-------------------------*/
@media (max-width: 1025px) {
  .cardsContainer {
    justify-content: center;
  }
  .card_image {
    height: 65%;
  }

}
@media (max-width: 768px) {
  .cardsContainer {
    justify-content: center;
  }
}
@media (max-width: 590px) {
  .cardsContainer {
    justify-content: center;
  }
}
