/*------------------------------------*\
  # Settings 
\*------------------------------------*/
.settings__container__settings{
  width: 100%;
}
.settings__title {
    font-family: AvenirRoman;
    font-size: 48px;
    color: #323232;
    margin-bottom: 20px;
}
/*------------------------------------*\
  # Settings Change_Password 
\*------------------------------------*/
.settings__change__password__card{
  width: 45%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px;
  font-size: 16px;
  color: #595959;
  font-family: AvenirBook;
}
.settings__change__password__card h3{
  font-size: 18px;
}

.change__password__card__title{
  width: 100%;
  font-weight: bold;
  text-align: left;
}
/*------------------------------------*\
  # Settings Change_Password Form
\*------------------------------------*/
.change__password__card__form{
  width: 100%;
}
.form__label {
  font-family: AvenirRoman;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #595959;
}
.form__input {
  width: 100%;
  position: relative;
}
.form__button {
  justify-self: center;
  align-self: center;
  min-width: 180px;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
}
.form__button,
.ant-btn {
 font-family: AvenirRoman;
 margin-bottom: -20px;
 margin-top: 5%;
}
.settings__change__password__form__button{
  justify-self: center;
  align-self: center;
  min-width: 180px;
  max-width: 350px;
  margin: 0 auto;
}
@media( max-width: 775px ){
  .settings__change__password__card{
    width: 100%;
  }
}