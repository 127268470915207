@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");

@font-face {
  font-family: AvenirBlack;
  src: url("./assets/fonts/AvenirLTStd-Black.otf");
}
@font-face {
  font-family: AvenirBook;
  src: url("./assets/fonts/AvenirLTStd-Book.otf");
}
@font-face {
  font-family: AvenirRoman;
  src: url("./assets/fonts/AvenirLTStd-Roman.otf");
}

.ant-layout{
  overflow: inherit !important;
}