/*-------------------------*\
    #Baner
\*-------------------------*/
.ContactBanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 293px;
    overflow: hidden;
    position: relative;
    background: url("../../assets/image.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

  .ContactBanner__title{
   position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
      
   }
   .ContactBanner__paragraph{
      font-family: Paytone One;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      color: #FFFFFF;
   }
/*-------------------------*\
    #Form
\*-------------------------*/
  .formContact {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     margin: 48px 0;
  }
  
  .formContact__h1 {
     font-family: Paytone One;
     font-size: 24px;
     line-height: 34px;
     color: #595959;
  }

  .formContact__paragraph {
     width: 90%;
     max-width: 478px;
     font-family: Avenir;
     font-size: 16px;
     line-height: 24px;
     text-align: center;
     color: #595959;
     margin: 24px 0;
  }
  .formContact__form {
     max-width: 580px;
     width: 80%;
     display: flex;
     flex-direction: column;
     align-items: center;
  }
   
  .form__button {
     justify-self: center;
     align-self: center;
     min-width: 180px;
     max-width: 350px;
     width: 100%;
     margin: 0 auto;
  }
  .form__button,
  .ant-btn {
    font-family: AvenirRoman;
    margin-bottom: -20px;
    margin-top: 5%;
  }
  .form__button:hover {
    background: #799b3b !important;
  }
  .ant-btn > span {
    padding-top: 1.5%;
  }
  
  .form__label {
    font-family: ABeeZee;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #595959;
  }
  .form__input {
     width: 100%;
     position: relative;
  }

  .form__input--textarea {
     width: 100%;
     position: relative;
     min-height: 96px;
  }
  /*----------------------------------*\
    #Tablet
   \*----------------------------------*/
   @media (min-width: 1440px) {
   .ContactBanner__paragraph{
     
      font-size: 36px;
   }
   }