/*------------------------------------*\
  #general
\*------------------------------------*/
.rewards__title {
  font-family: AvenirRoman;
  font-size: 48px;
  color: #323232;
  display: flex;
  align-items: center;
}
.rewards__content {
  font-family: AvenirBook;
  font-size: 18px;
}
.rewards__title--points {
  color: #95bf48;
  font-weight: 800;
}
/*------------------------------------*\
    #Tabs section
  \*------------------------------------*/
.rewards--container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.rewards--categories {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

/*------------------------------------*\
    #Table 
  \*------------------------------------*/
.reward--image {
  width: 45px;
  height: 45px;
  object-fit: contain;
  cursor: pointer;
}
.reward--delete {
  color: #595959;
  font-size: 20px;
  cursor: pointer;
}
.reward--delete:hover {
  color: rgb(233, 96, 96);
}
/*------------------------------------*\
    #Bottom
  \*------------------------------------*/
.rewards__button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.rewards__add-button {
  border: none;
  margin: 32px 0 50px 0;
  position: relative;
  right: 0;
  background: #95bf48;
  border-radius: 20px;
  font-family: AvenirBook;
  color: #ffffff;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  width: 156px;
  height: 40px;
}
.rewards__add-button:hover {
  background: #5d7e20;
}

.modal__title {
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 24px;
  color: #000000;
}
.modal__content {
  font-family: AvenirBook;
  font-size: 18px;
  line-height: 19px;
  padding: 0 10%;
  margin: 30px 0;
}
.modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.modal__button--cancel {
  background: none;
  width: 105px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 18px;
  color: #c4c4c4;
  margin: 0 8px;
}
.modal__button--cancel:hover {
  border: 1px solid #95bf48;
  color: #95bf48;
}
.modal__button--delete {
  border: none;
  width: 105px;
  height: 40px;
  background: #95bf48;
  font-family: AvenirRoman;
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
  margin: 0 8px;
  cursor: pointer;
}
.modal__button--delete:hover {
  background: #5d7e20;
}
